import React, { FC, useEffect, useState } from 'react';
import './filterOptions.scss';
import { api } from '../../../services/Api';
import { ICategory } from '../../../types';
import {
  CategoriesListFilter,
  FieldsSelect,
  LocationSelect,
  PriceSlider,
  SearchInput,
  StateSelect,
} from '.';
import { ISearchRequest } from '../../../services/types';
import { Icon } from '../../default_components';
import { Helmet } from 'react-helmet';
import { useGetHeadAttributes } from '../../../hooks/useGetHeadAttributes';
import { useLocation } from 'react-router-dom';
import HandmadeOption from './handmadeOption/HandmadeOption';

interface IFilterOptions {
  category_id: string | undefined;
  search: ({}: ISearchRequest) => void;
  isSwiper?: boolean;
  closeDrawer?: () => void;
}

const FilterOptions: FC<IFilterOptions> = ({ category_id, search, isSwiper, closeDrawer }) => {
  const { data: categories } = api.useGetAllCategoriesQuery();
  const { data: locations } = api.useGetLocationsQuery();

  const location = useLocation();
  const { title, description } = useGetHeadAttributes(location.pathname);

  const [pageTitle, setPageTitle] = useState<string | undefined>(title);
  const [pageDescription, setPageDescription] = useState<string | undefined>(description);

  const [category, setCategory] = useState<ICategory | undefined>();

  useEffect(() => {
    if (categories && category_id) {
      const currentCategory = categories.find(category => category.id === category_id);
      setCategory(currentCategory);
    }
  }, [categories, category_id]);

  useEffect(() => {
    if (!category?.description && category?.parents.length) {
      const parentCategory = categories?.find(cat => cat.id === category?.parents[0].id);
      setPageDescription(parentCategory?.description);
      setPageTitle(`${parentCategory?.name} - Gamarmarket.ge`);

      return;
    }

    if (!category?.parents.length && category?.description) {
      setPageDescription(category.description);
      setPageTitle(`${category?.name} - Gamarmarket.ge`);

      return;
    }

    if (!category) {
      setPageDescription(description);
      setPageTitle(title);
    }
  }, [category, categories]);

  return (
    <div className={`filter-options_frame ${isSwiper && 'is-swiper'}`}>
      <Helmet>
        <title>{pageTitle}</title>

        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={category?.image || category?.parents[0]?.image || ''} />
      </Helmet>

      {isSwiper && (
        <div className="filter-menu__title close-button" onClick={closeDrawer}>
          <Icon type="crossDefault" />
        </div>
      )}

      <CategoriesListFilter selectedCategory={category} />

      <PriceSlider search={search} />

      <StateSelect search={search} />

      <HandmadeOption search={search} />

      {locations && (
        <LocationSelect
          locations={[...locations].sort((a, b) => (a.name > b.name ? 1 : -1))}
          search={search}
        />
      )}

      {category &&
        category.fields.map(field => <FieldsSelect key={field.id} field={field} search={search} />)}
    </div>
  );
};

export default FilterOptions;
