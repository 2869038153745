import React, { FC, ReactElement, SetStateAction, useEffect, useState } from 'react';
import './header.scss';
import Logo from '../default_components/assets/icons/HeaderLogo.svg';
import { ICategory, IChatRoom } from '../../types';
import { useAppSelector } from '../../hooks/redux';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { localStorageKeys, REDIRECT_URLS } from '../../routes';
import { AppBar, Slide, useScrollTrigger } from '@mui/material';
import HeaderMenu from './headerMenu/HeaderMenu';
import LocaleAndCurrency from './localeAndCurrency/LocaleAndCurrency';
import SearchField from './searchField/SearchField';
import Authorization from '../auth/Authorization';
import crossSmall from '../default_components/assets/icons/CrossSmall.svg';
import { t } from 'i18next';
import { useLocaleFromUrl } from '../../utils/localeFromUrl';

interface IHeader {
  resetDashboardFilters?: React.Dispatch<SetStateAction<ICategory | undefined>>;
  selectedChat?: IChatRoom;
  isChat?: boolean;
}

function HideOnScroll(props: { children: ReactElement }) {
  const { children } = props;
  const trigger = useScrollTrigger({ target: window });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header: FC<IHeader> = React.memo(({ resetDashboardFilters, selectedChat, isChat }) => {
  const path = window.location.pathname;
  const localeStr = useLocaleFromUrl();
  const { isAuthorized } = useAppSelector(state => state.authReducer);

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [redirectUrl, setRedirectUrl] = useState<string>(REDIRECT_URLS.DASHBOARD);
  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(path == '/register');
  const [showPromoLine, setShowPromoLine] = useState<boolean>(
    localStorage.getItem(localStorageKeys.promo) === 'true',
  );

  const styleAppbar = {
    minWidth: '320px',
    background: '#F9F9F9',
    boxShadow: scrollPosition > 10 ? '0px 7px 10px rgba(189, 189, 189, 0.25)' : 'none',
    zIndex: '10',
    '@media (max-width: 768px)': {
      display: selectedChat && 'none',
    },
  };

  useScrollPosition(setScrollPosition);

  const clickOnLogo = () => resetDashboardFilters && resetDashboardFilters(undefined);

  const clickOnPromoLine = () => {
    setOpenAuthPopup(true);
    setRedirectUrl(REDIRECT_URLS.CREATION_PAGE);
  };

  useEffect(() => {
    setShowPromoLine(!isAuthorized);
  }, [isAuthorized]);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.promo, `${!isAuthorized}`);
  }, [isAuthorized, showPromoLine]);

  return (
    <React.Fragment>
      <HideOnScroll>
        <AppBar position={isChat ? 'relative' : 'sticky'} sx={styleAppbar}>
          {showPromoLine && (
            <div className="header__promo-line">
              <span onClick={clickOnPromoLine} className="promo-line__text">
                {t('translation:header.promo_line.message')}
              </span>

              <img
                className="promo-line__icon"
                alt="close"
                src={crossSmall}
                onClick={() => {
                  localStorage.setItem(localStorageKeys.promo, 'false');
                  setShowPromoLine(false);
                }}
              />
            </div>
          )}

          <div className="header__container" data-cy="header">
            <div className="header">
              <div onClick={clickOnLogo} className="header__logo">
                <a href={localeStr ? `${localeStr}/dashboard` : `/dashboard`}>
                  <img src={Logo} alt="Gamarmarket.ge" />
                </a>
              </div>

              <LocaleAndCurrency />
              <SearchField />
              <HeaderMenu />
            </div>
          </div>
        </AppBar>
      </HideOnScroll>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={redirectUrl}
      />
    </React.Fragment>
  );
});

export default Header;
