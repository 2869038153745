import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';

const MobilePolicy = () => {
  const highlightEmail = (text: string) => {
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    return text.replace(emailRegex, match => `<a href="mailto:${match}">${match}</a>`);
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე" />
        <meta
          property="og:description"
          content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."
        />
        <meta
          property="og:image"
          content="https://gamar-api.s3.eu-central-1.amazonaws.com/OG_LOGO.png"
        />
        <meta property="og:url" content="https://gamarmarket.ge" />

        <meta name="twitter:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე" />
        <meta
          name="twitter:description"
          content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."
        />
        <meta name="twitter:url" content="https://gamarmarket.ge" />
        <meta name="twitter:card" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია." />
      </Helmet>
      <Header />

      <div className="static-page__frame">
        <div className="static-page__title-container">
          <h1>{t('translation:footer.mobile_policy.message')}</h1>
        </div>

        <hr className="divider" />

        <p className="static-page__content">
          {t('translation:mobile_policy.text_1.message')}
          <hr className="hr" />

          <article>
            <li>{t('translation:mobile_policy.text_2.message')}</li>
            <hr className="hr-inner" />
            <div>
              {(
                t('translation:mobile_policy.items_text_4', {
                  returnObjects: true,
                }) as unknown as Array<string>
              ).map((text: string) => (
                <li className="list-item">{text}</li>
              ))}
            </div>
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_3.message')}
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_4.message')}
            <hr className="hr-inner" />
            <div>
              {(
                t('translation:mobile_policy.items_text_4', {
                  returnObjects: true,
                }) as unknown as Array<string>
              ).map((text: string) => (
                <li className="list-item">{text}</li>
              ))}
            </div>
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_5.message')}
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_6.message')}
          </article>
          <hr className="hr" />

          <article>
            <li>{t('translation:mobile_policy.text_7.message')}</li>
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_8.message')}
            <hr className="hr-inner" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {(
                t('translation:mobile_policy.items_text_8', {
                  returnObjects: true,
                }) as unknown as Record<string, string>[]
              ).map(obj => (
                <li className="list-item">
                  <a href={obj.link} target="_blank" rel="noopener noreferrer">
                    {obj.message}
                  </a>
                </li>
              ))}
            </div>
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_9.message')}
            <hr className="hr-inner" />
            <div>
              {(
                t('translation:mobile_policy.items_text_9', {
                  returnObjects: true,
                }) as unknown as Array<string>
              ).map((text: string) => (
                <li className="list-item">{text}</li>
              ))}
            </div>
          </article>
          <hr className="hr" />

          <li> {t('translation:mobile_policy.text_10.message')}</li>
          <hr className="hr" />

          <li
            dangerouslySetInnerHTML={{
              __html: highlightEmail(t('translation:mobile_policy.text_11.message')),
            }}
          />
          <hr className="hr" />

          <article>
            <li> {t('translation:mobile_policy.text_12.message')}</li>
            <hr className="hr-inner" />
            <div
              dangerouslySetInnerHTML={{
                __html: highlightEmail(t('translation:mobile_policy.text_13.message')),
              }}
            />
          </article>
          <hr className="hr" />

          <li> {t('translation:mobile_policy.text_14.message')}</li>
          <hr className="hr" />

          <article>
            <li> {t('translation:mobile_policy.text_15.message')}</li>
            <hr className="hr-inner" />
            {t('translation:mobile_policy.text_16.message')}
          </article>
          <hr className="hr" />

          <li> {t('translation:mobile_policy.text_17.message')}</li>
          <hr className="hr" />

          <li
            dangerouslySetInnerHTML={{
              __html: highlightEmail(t('translation:mobile_policy.text_18.message')),
            }}
          />
          <hr className="hr" />
        </p>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default MobilePolicy;
